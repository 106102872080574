.slide-block {
    text-align: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }
  
  .slide-block.open {
    max-height: 1000px;
    opacity: 1;
  }
  
  .slide-block.closed {
    max-height: 0;
    opacity: 0;
  }