.inputBox {
    border-radius: 2em;
    border: 0.2em solid;
    padding: 1.5em;
}

.attackerBox {
    border-color: rgb(237, 43, 43);
}

.defenderBox {
    border-color: rgb(17, 123, 237, 0.4);
}

.defaultBox {
    border-color: rgb(84, 84, 84);
}

.activated {
    background-color: rgba(242, 232, 36, 0.5);
}